import React from 'react';
import { Center, Text, Heading, Image } from '@chakra-ui/react';

function App() {
   return (
      <Center
         h="100vh"
         w="100vw"
         background="#4E1672"
      >
         <Image src="/logo.svg" h="10" mt="2.5" mr="2" />
         <Heading color="white">
            WEB
         </Heading>
      </Center>
   );
}

export default App;
